.navbar {
  padding: 1rem 0rem 1rem 0rem !important;
  margin: 0px !important;
  z-index: 9999 !important;
}

/* Hiding the nav bar in Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbar {
    display: none !important;
  }
}
