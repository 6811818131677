@media only screen and (max-width: 768px) {
  .img_top_margin_xs {
    margin-top: 1rem !important;
  }
}

.hover_effect {
  font-size: 60px; /* Initial font size */
  transition: font-size 0.3s, color 0.3s; /* Add a smooth transition effect */
  font-weight: 400 !important;
}

.hover_effect:hover {
  font-size: 64px; /* Increase font size by 10% on hover */
  color: transparent; /* Make the original text color transparent */
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #ff00ff, #00ffff, #00ff00, #0000ff);
  -webkit-background-clip: text;
  background-clip: text;
}

.no_decoration {
  text-decoration: none !important;
}

.margin_top_0px_flex {
  margin-top: 0px !important;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.margin_left_8px {
  margin-left: 8px !important;
}
