body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main_box {
    padding: 0rem 1.5rem 0rem 1.5rem !important;
  }
}
