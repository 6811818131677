.grid_class {
  display: grid;
}

.exp_li {
  margin-top: "1rem !important";
}

.flex_container {
  display: flex; /* Enables flexbox */
  justify-content: space-between; /* Aligns children to left and right extremes */
  align-items: center; /* Centers items vertically if needed */
  width: 100%; /* Full width of the page */
  padding: 0 0 10px 0; /* Optional padding for spacing */
  flex-wrap: wrap;
}

.left_text {
  text-align: left; /* Aligns text to the left */
}

.right_text {
  text-align: right; /* Aligns text to the right */
  color: #9e9e9e; /* Red color for right text */
}
